import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
      // type: 'dark',
      background: {
        paper: '#fafafa'
      }
    },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif'
  },
});

export default theme;
